import React from 'react'

import { PageHeader, Container } from 'maxboapp-components'

export default function ErrorPage() {
	return (
		<>
			<PageHeader title='404' />
			<Container>
				{/* TODO: is this shown in production? */}
				<p>Innhold kommer her. </p>
			</Container>
		</>
	)
}
